<template>
  <div class="modal-g">
    <div class="modal-g__wrapper">
      <div class="modal-g__container">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer">
          <div class="modal-g__buttons">
            <button class="modal-g__button cancel" @click="clickCancel" v-if="isCancel">Cancelar</button>
            <button class="modal-g__button submit" @click="clickSubmit" ref="submit" :disabled="isDisableSubmit" autofocus="false">
              Aceptar
            </button>
          </div>
        </slot>
      </div>
    </div>
    <div class="modal-g__mask" v-if="showMask" @click="clickCancel"></div>
  </div>
</template>

<script>
  export default {
    props: {
      isCancel: { type: Boolean, default: true },
      isDisableSubmit: { type: Boolean, default: true },
      showMask: { type: Boolean, default: false },
    },
    methods: {
      clickSubmit() {
        this.$emit("onSubmit");
      },
      clickCancel() {
        this.$emit("onCancel");
      },
      disabledButtonSubmit() {
        this.$refs.submit.setAttribute("disabled", "true");
      },
      enabledButtonSubmit() {
        this.$refs.submit.removeAttribute("disabled");
      },
    },
    mounted() {
      if (this.showMask) this.$emit("open");
    },
  };
</script>

<style lang="scss">
  .modal-g {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.144);
    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1001;
    }
    &__wrapper {
      @include Row();
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__container {
      @include Column();
      width: 500px;
      height: auto;
      max-height: 80vh;
      padding: 10px;
      border-radius: 12px;
      background-color: $white;
      box-shadow: $dshadow;
      overflow-y: auto;
      z-index: 1002;
    }
    &__button {
      @include ButtonStyle();
      background-color: $primary_color;
      padding: $mpadding/2;
      border: none;
      margin: 10px;
      &[disabled] {
        opacity: 0.5;
        &:active {
          transform: initial;
        }
      }
      &.cancel {
        background-color: $gray-emperor;
      }
    }
  }
</style>
